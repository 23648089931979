import * as React from 'react'
import styled from 'styled-components'

const StyledButton = styled.a`
    display: inline-block;
    background-color: #ff4846;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: .25rem;
    padding: .5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
    
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;

    &:hover {
        background-color: #f11311;
    }
`

export const Button = props => {

    const {link, label, rel} = props
        
    return <StyledButton href={link} rel={rel}>{label}</StyledButton>
}