import React from 'react'
import styled from 'styled-components'

import { Button } from '../components/Button'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100vh;
`

const Item = styled.div`
    margin: auto;
    width: auto;
    text-align: center;

    h1 {
        font-size: 10rem;
        font-weight: 900;
        color: #444444;
        line-height: 10rem;
    }

    h2 {
        margin-bottom: 2rem;
    }
`

const NotFoundPage = () => {
    return (
        <Wrapper>
            <Item>
                <h1>404</h1>
                <h2>متأسفانه صفحه مورد نظر یافت نشد.</h2>
                <Button label="بازگشت به صفحه اصلی" link="https://neginfood.de" rel="follow" />
            </Item>
        </Wrapper>
    )
}

export default NotFoundPage